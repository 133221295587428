<template>
    <div class="container">
        <!-- 面包屑导航 -->
        <Breadcrumb :breadList="breadList"> </Breadcrumb>
        <a-form-model
            class="formModal"
            ref="form"
            :rules="rules"
            :model="form"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
            <div class="baseInfo">商品基本信息</div>
            <br />
            <a-form-model-item
                prop="name"
                label="优惠券名称"
            >
                <a-input
                    v-model="form.name"
                    placeholder="请输入内容"
                />
            </a-form-model-item>
            <a-form-model-item
                prop="memo"
                label="备注"
            >
                <a-input
                    v-model="form.memo"
                    type="textarea"
                    placeholder="请输入内容"
                />
            </a-form-model-item>
            <a-form-model-item
                prop="total"
                label="发放总量"
            >
                <a-input
                    v-model="form.total"
                    suffix="张"
                    placeholder="请输入内容"
                />
            </a-form-model-item>
            <a-form-model-item
                v-if="propertyGroupObj.getChannel"
                prop="channel"
                label="适用渠道"
            >
                <a-select
                    :default-value="form.channel"
                    :key="form.channel"
                    placeholder="请选择"
                    @change="(e)=>{
                        form.channel = e;
                        $forceUpdate()
                   }"
                >
                    <a-select-option
                        v-for="d in channelList"
                        :key="d.id"
                        :value="d.id"
                    >
                        {{d.name}}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item
                prop="goodsLimitSwitch"
                v-if="propertyGroupObj.goodsLimit"
                label="适用商品"
            >
                <div><span style="color:#ccc;">若活动商品中存在分销商品，优惠后可能造成利润受损。</span></div>
                <a-radio-group v-model="form.goodsLimitSwitch">
                    <a-radio
                        :style="radioStyle"
                        value="0"
                    >
                        全部商品可用
                    </a-radio>
                    <a-radio
                        :style="radioStyle"
                        value="1"
                    >
                        指定商品可用
                    </a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item
                v-if="form.goodsLimitSwitch === '1'"
                label=" "
                :colon="false"
            >
                <a-form-model-item
                    :colon="false"
                    prop="goodsLimit"
                >
                    <div><a @click="goodsLimitModalShow = true; goodsLimitCode = form.goodsLimit || 'goods'">指定商品或商品分类</a></div>
                    <div><span style="color:#ccc;">最多选择1000个商品或30个商品分组</span></div>
                    <a-modal
                        v-model="goodsLimitModalShow"
                        title="选择可用商品"
                        :width="1700"
                        :footer="null"
                    >
                        <div style="display: flex;">
                            <div style="flex:1;">
                                <a-radio-group v-if="propertyGroupObj && propertyGroupObj.goodsLimit && propertyGroupObj.goodsLimit.property" v-model="goodsLimitCode">
                                        <a-radio
                                            :style="radioStyle"
                                            value="goods"
                                        >
                                            适用商品
                                        </a-radio>
                                        <a-radio
                                            :style="radioStyle"
                                            value="goodsType"
                                        >
                                            适用分组
                                        </a-radio>
                                </a-radio-group>
                            </div>
                            <div flex="1">
                                <SelectGoods
                                    @closeModal="goodsLimitModalShow=false"
                                    @changeGoosLimitCode = "(e)=>{form.goodsLimit = e;refreshDomFn();}"
                                    v-if="goodsLimitCode == 'goods' && goodsLimitModalShow"
                                ></SelectGoods>
                                <SelectGoodsGroup
                                    @closeModal="goodsLimitModalShow=false"
                                    @changeGoosLimitCode = "(e)=>{form.goodsLimit = e;refreshDomFn();}"
                                    v-if="goodsLimitCode == 'goodsType' && goodsLimitModalShow"
                                ></SelectGoodsGroup>
                            </div>
                        </div>
                    </a-modal>
                </a-form-model-item>
                <div v-if="refreshDom">
                    <SelectGoodsMicro v-if="form.goodsLimit =='goods'"></SelectGoodsMicro>
                    <SelectGoodsGroupMicro v-if="form.goodsLimit =='goodsType'"></SelectGoodsGroupMicro>
                </div>
            </a-form-model-item>
            <a-form-model-item
                prop="orderLimit"
                v-if="propertyGroupObj.orderLimit"
                label="使用门槛"
            >
                <a-radio-group
                    style="margin-top:5px;"
                    default-value=""
                    v-model="form.orderLimit"
                >
                    <a-radio
                        :style="radioStyle"
                        value="0"
                    >
                        无使用门槛
                    </a-radio>
                    <template
                        plate
                        v-for="d in propertyGroupObj.orderLimit.property"
                    >

                        <a-radio
                            :key="d.id"
                            :style="radioStyle"
                            :value="d.propertyCode"
                        >
                            {{d.propertyTypeName}}
                            &emsp;订单满
                            <a-input
                                v-if="d.propertyCode==='orderLimitAmount'"
                                :key="d.id"
                                style="width:100px;"
                                v-model="form.orderLimitAmount"
                                @change="$refs.form.validateField(['orderLimitAmount'])"
                                suffix="元"
                                placeholder="请输入内容"
                            />
                            可用
                        </a-radio>
                    </template>
                    <a-form-model-item
                        v-if="form.orderLimit !== '0'"
                        prop="orderLimitAmount"
                        label=""
                    >
                    </a-form-model-item>

                </a-radio-group>
            </a-form-model-item>

            <a-form-model-item
                prop="benefit"
                v-if="propertyGroupObj.benefit"
                label="优惠内容"
            >
                <template v-for="d in propertyGroupObj.benefit.property">
                    <a-input
                        :key="d.id"
                        v-model="form.benefit"
                        suffix="元"
                        :placeholder="d.propertyTypeName"
                    />
                </template>

            </a-form-model-item>
            <a-form-model-item
                prop="timeLimit"
                v-if="propertyGroupObj.timeLimit"
                label="用券时间"
            >
                <a-radio-group
                    style="margin-top:5px;"
                    default-value=""
                    v-model="form.timeLimit"
                    @change="$refs.form.validateField(['timeLimit'])"
                >
                    <div
                        style="margin-bottom:20px;"
                        v-for="d in propertyGroupObj.timeLimit.property"
                        :key="d.id"
                    >
                        <a-radio :value="d.propertyCode">
                            {{d.propertyName}}
                        </a-radio>
                        <template v-if="d.propertyCode === 'validTime'">
                            <a-date-picker
                                :disabled-date="disabledDateStart"
                                :valueFormat="dateFormat"
                                :format="dateFormat"
                                @change="$refs.form.validateField('gmtAvlidStart')"
                                v-model="form.gmtAvlidStart"
                            />
                            &emsp;至 &emsp;
                            <a-date-picker
                                :disabled-date="disabledDateStart"
                                :valueFormat="dateFormat"
                                :format="dateFormat"
                                @change="$refs.form.validateField('gmtAvlidEnd')"
                                v-model="form.gmtAvlidEnd"
                            />
                        </template>
                        <template v-if="d.propertyCode === 'validDay'">
                            领券后立即生效,有效期
                            <a-input
                                style="width:100px;"
                                v-model="form.validDay"
                                suffix="天"
                                placeholder="请输入内容"
                            />
                        </template>
                        <template v-if="d.propertyCode === 'validAfterDay'">
                            领券后
                            <a-input
                                style="width:100px;"
                                v-model="form.validDay1"
                                suffix="天"
                                placeholder="请输入内容"
                            />
                            生效,有效期
                            <a-input
                                style="width:100px;"
                                v-model="form.validDay2"
                                suffix="天"
                                placeholder="请输入内容"
                            />
                        </template>

                    </div>
                </a-radio-group>
                <div><span style="color:#ccc;">若设置固定用券时间，编辑保存后对已领取及后续领取的券均生效。</span></div>
                <div><span style="color:#ccc;">若设置动态用券时间，编辑保存后仅对后续领取的券生效</span></div>
                <a-form-model-item
                    v-if="form.timeLimit==='validTime'"
                    prop="gmtAvlidStart"
                >
                </a-form-model-item>
                <a-form-model-item
                    v-if="form.timeLimit==='validTime'"
                    prop="gmtAvlidEnd"
                >
                </a-form-model-item>
                <a-form-model-item
                    v-if="form.timeLimit==='validDay'"
                    prop="validDay"
                >
                </a-form-model-item>
                <a-form-model-item
                    v-if="form.timeLimit==='validAfterDay'"
                    prop="validDay1"
                >
                </a-form-model-item>
                <a-form-model-item
                    v-if="form.timeLimit==='validAfterDay'"
                    prop="validDay2"
                >
                </a-form-model-item>
            </a-form-model-item>
            <a-form-model-item label="优惠说明">
                <a-input
                    v-model="form.description"
                    type="textarea"
                    placeholder="请输入内容"
                />
            </a-form-model-item>
            <div class="baseInfo">发放领取规则</div>
            <br />
            <!-- <a-form-model-item
                prop="getLimit"
                v-if="propertyGroupObj.getLimit"
                label="领取人限制"
            >
                <a-radio-group
                    v-model="form.getLimit"
                >
                    <a-radio
                        :style="radioStyle"
                        value=""
                    >
                        不限制，所有人可领
                    </a-radio>
                </a-radio-group>
            </a-form-model-item> -->
            <a-form-model-item
                prop="getLimit"
                v-if="propertyGroupObj.getLimit"
                label="每人限领次数"
            >
                <a-radio-group
                    style="margin-top:5px;"
                    default-value=""
                    v-model="form.getLimit"
                >
                    <a-radio
                        :style="radioStyle"
                        value="0"
                    >
                        不限次数
                    </a-radio>
                    <template v-for="d in propertyGroupObj.getLimit.property">
                        <a-radio
                            :key="d.id"
                            :style="radioStyle"
                            :value="d.propertyCode"
                        >
                            {{d.propertyName}}
                            <a-input
                                :key="d.id"
                                style="width:100px;"
                                v-model="form.getLimitQty"
                                suffix="张"
                                @change="$refs.form.validateField(['getLimitQty'])"
                                placeholder="请输入内容"
                            />
                        </a-radio>
                    </template>

                </a-radio-group>
                <a-form-model-item
                    v-if="form.getLimit === 'getLimitQty'"
                    prop="getLimitQty"
                ></a-form-model-item>
            </a-form-model-item>
            <a-form-model-item
                prop="getCouponAuto"
                v-if="propertyGroupObj.getCouponAuto"
                label="自动领取方式"
            >
                <a-radio-group style="margin-top:5px;" v-model="form.getCouponAuto">
                    <a-radio
                        :style="radioStyle"
                        value="0"
                    >
                        不自动
                    </a-radio>
                    <template v-for="d in propertyGroupObj.getCouponAuto.property">
                        <a-radio
                            :key="d.id"
                            :style="radioStyle"
                            :value="d.propertyCode"
                        >
                            {{d.propertyName}}
                        </a-radio>
                    </template>

                </a-radio-group>
            </a-form-model-item>
            <br />
            <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                <a-button @click="cancelCoupon">
                    取消
                </a-button>
                <a-button
                    type="primary"
                    @click="onSubmit"
                    style="margin-left: 10px;"
                >
                    保存
                </a-button>
                <br />
                <br />
                <br />
                <br />
            </a-form-model-item>
        </a-form-model>
    </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import SelectGoods from './components/goodsSelect.vue'
import SelectGoodsMicro from './components/goodsSelectMicro.vue'
import SelectGoodsGroup from './components/goodsGroupSelect.vue'
import SelectGoodsGroupMicro from './components/goodsGroupSelectMicro.vue'
export default {
  components: {
    Breadcrumb,
    SelectGoods,
    SelectGoodsGroup,
    SelectGoodsMicro,
    SelectGoodsGroupMicro
  },
  data () {
    // let orderLimitValidator = (rule, value, callback) => {
    //   // eslint-disable-next-line eqeqeq
    //   if (this.form.orderLimit == '0') {
    //     return callback()
    //   }
    //   console.log('ccccccc')
    //   // eslint-disable-next-line eqeqeq
    //   if (!this.form.couponEx[value]) {
    //     // return callback(new Error('请输入数值'))
    //   }
    //   if (Number.isNaN(Number(this.form.couponEx[value]))) {
    //     return callback(new Error('请输入数值111'))
    //   }
    // }
    return {
      breadList: [
        {
          name: '营销管理',
          url: ''
        },
        {
          name: '优惠券',
          changeParent: true,
          url: '/shop/coupon/list'
        },
        {
          name: '新建优惠券',
          url: ''
        }
      ],
      refreshDom: true,
      dateFormat: 'YYYY-MM-DD 00:00:00',
      dateFormatEnd: 'YYYY-MM-DD 23:59:59',
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      propertyGroupObj: {}, // 所有特性
      goodsLimitCode: '',
      goodsLimitModalShow: false, // 指定商品窗口开关
      channelList: [], // 渠道列表
      form: {
        id: undefined,
        total: undefined,
        typeId: 2,
        name: '',
        propertyCodeList: [],
        couponEx: {},
        description: '', // 优惠说明
        // 以下是临时为校验做的数据,后面可清除
        channel: '0', // 渠道 0代表全渠道
        goodsLimitSwitch: '0', // 商品可用开关
        goodsLimit: 'goods', // 默认限制为商品
        orderLimit: '0',
        orderLimitAmount: undefined, // 门槛值校验
        benefit: undefined,
        timeLimit: undefined,
        gmtAvlidEnd: undefined,
        gmtAvlidStart: undefined,
        validDay: undefined,
        validDay1: undefined,
        validDay2: undefined,
        getLimit: '0',
        getLimitQty: undefined,
        getCouponAuto: 'getCouponOneAndOne'
      },
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
      },
      rules: {
        name: [
          { required: true, message: '请输入优惠券名称', trigger: 'blur' },
          { min: 3, max: 20, message: '优惠券名称长度限制', trigger: 'blur' }
        ],
        total: [
          { required: true, message: '请输入', trigger: 'blur' },
          {
            pattern: new RegExp('^[0-9]{1,}$', 'g'),
            message: '只允许包含数字'
          }
        ],
        channel: [{ required: true, message: '请选择', trigger: 'blur' }],
        goodsLimit: [
          { required: true, message: '请选择商品或者商品组', trigger: 'blur' }
        ],
        goodsLimitSwitch: [
          { required: true, message: '请选择', trigger: 'blur' }
        ],
        orderLimit: [{ required: true, message: '请选择', trigger: 'blur' }],
        orderLimitAmount: [
          { required: true, message: '请输入内容', trigger: 'blur' },
          {
            pattern: new RegExp('^[0-9]{1,}$', 'g'),
            message: '只允许包含数字'
          },
          { min: 0, max: 10, message: '数量超了', trigger: 'blur' }
        ],
        benefit: [
          { required: true, message: '请输入', trigger: 'blur' },
          {
            pattern: new RegExp('^[0-9]{1,}$', 'g'),
            message: '只允许包含数字'
          }
        ],
        timeLimit: [
          { required: true, message: '请输入用券时间', trigger: 'blur' }
        ],
        gmtAvlidEnd: [
          { required: true, message: '请输入结束时间', trigger: 'blur' }
        ],
        validDay: [
          { required: true, message: '请输入有效期', trigger: 'blur' },
          {
            pattern: new RegExp('^[0-9]{1,}$', 'g'),
            message: '只允许包含数字'
          }
        ],
        validDay1: [
          { required: true, message: '请输入开始天数', trigger: 'blur' },
          {
            pattern: new RegExp('^[0-9]{1,}$', 'g'),
            message: '只允许包含数字'
          }
        ],
        validDay2: [
          { required: true, message: '请输入结束天数', trigger: 'blur' },
          {
            pattern: new RegExp('^[0-9]{1,}$', 'g'),
            message: '只允许包含数字'
          }
        ],
        gmtAvlidStart: [
          { required: true, message: '请输入开始时间', trigger: 'blur' }
        ],
        getLimit: [{ required: true, message: '请选择', trigger: 'blur' }],
        getLimitQty: [
          { required: true, message: '请输入', trigger: 'blur' },
          {
            pattern: new RegExp('^[0-9]{1,}$', 'g'),
            message: '只允许包含数字'
          }
        ],
        getCouponAuto: [{ required: true, message: '请选择', trigger: 'blur' }]
      }
    }
  },
  async created () {
    this.getInfoByCouponType()
    if (this.$route.query.id) {
      this.form.id = this.$route.query.id
      this.breadList[2].name = '编辑优惠券'
      const couponDetail = await this.$store.dispatch('coupon/getCouponDetail', this.form.id, { root: true })
      const { name, description, memo, total, propertyCodeList, couponEx } = couponDetail
      console.log(couponDetail)
      this.form.name = name
      this.form.description = description
      this.form.memo = memo
      this.form.total = total
      this.$forceUpdate()
      if (propertyCodeList && propertyCodeList) {
        // 渠道
        if (propertyCodeList.includes('channel')) {
          this.form.channel = couponEx.channel
        }

        // 商品限制
        if (propertyCodeList.includes('goods')) {
          this.$nextTick(() => {
            this.$store.commit('coupon/SET_GOODS_SELECTED', couponEx.goods, { root: true })
            this.form.goodsLimit = 'goods'
            this.form.goodsLimitSwitch = '1'
          })
        }
        // 商品分组限制
        if (propertyCodeList.includes('goodsType')) {
          this.$nextTick(() => {
            this.$store.commit('coupon/SET_GOODS_GROUP_SELECTED', couponEx.goodsType, { root: true })
            this.form.goodsLimit = 'goodsType'
            this.form.goodsLimitSwitch = '1'
          })
        }
        // 商品使用门槛
        if (propertyCodeList.includes('orderLimitAmount')) {
          this.$nextTick(() => {
            this.form.orderLimit = 'orderLimitAmount'
            this.form.orderLimitAmount = couponEx.orderLimitAmount.toString()
          })
        }
        // benefy 优惠内容
        if (propertyCodeList.includes('bonusAmount')) {
          this.$nextTick(() => {
            this.form.benefit = couponEx.bonusAmount
          })
        }
        // 用券时间
        if (propertyCodeList.includes('validTime')) {
          this.$nextTick(() => {
            this.form.timeLimit = 'validTime'
            this.form.gmtAvlidEnd = couponEx.validTime.gmtAvlidEnd
            this.form.gmtAvlidStart = couponEx.validTime.gmtAvlidStart
          })
        }
        if (propertyCodeList.includes('validDay')) {
          this.$nextTick(() => {
            this.form.timeLimit = 'validDay'
            this.form.validDay = couponEx.validDay
          })
        }
        if (propertyCodeList.includes('validAfterDay')) {
          this.$nextTick(() => {
            this.form.timeLimit = 'validAfterDay'
            this.form.validDay1 = couponEx.validAfterDay.validDay
            this.form.validDay2 = couponEx.validAfterDay.validAfterDay
          })
        }
        // getlimit每人限领次数
        if (propertyCodeList.includes('getLimitQty')) {
          this.$nextTick(() => {
            this.form.getLimit = 'getLimitQty'
            this.form.getLimitQty = couponEx.getLimitQty
          })
        }
        // 自动领取方式
        if (propertyCodeList.includes('getCouponOneAndOne')) {
          this.$nextTick(() => {
            this.form.getCouponAuto = 'getCouponOneAndOne'
          })
        }
        if (propertyCodeList.includes('getCouponOne')) {
          this.$nextTick(() => {
            this.form.getCouponAuto = 'getCouponOne'
          })
        }
      }
    }
    this.$store.commit('coupon/CLEAR_SELECTED_KEYS', {}, { root: true })
    this.$store.dispatch('coupon/goodsVirtualList', {}, { root: true })
  },

  computed: {
    couponState () {
      const {
        state: { coupon }
      } = this.$store
      return coupon
    }
  },
  watch: {
    form: {
      handler (newVal) {
        // eslint-disable-next-line eqeqeq
        if (newVal.orderLimit == '0') {
          this.$refs.form.clearValidate(['orderLimitAmount'])
        }
        if (newVal.orderLimit === 'orderLimitAmount') {
          this.$nextTick(() => {
            this.$refs.form.validateField(['orderLimitAmount'])
          })
        }
        if (newVal.timeLimit === 'validTime') {
          this.$nextTick(() => {
            this.$refs.form.validateField(['gmtAvlidEnd', 'gmtAvlidStart'])
          })
        }
        if (newVal.timeLimit === 'validDay') {
          this.$nextTick(() => {
            this.$refs.form.validateField(['validDay'])
          })
        }
        if (newVal.timeLimit === 'validAfterDay') {
          this.$nextTick(() => {
            this.$refs.form.validateField(['validDay1', 'validDay2'])
          })
        }
        if (newVal.getLimit === 'getLimitQty') {
          this.$nextTick(() => {
            this.$refs.form.validateField(['getLimitQty'])
          })
        }
      },
      deep: true
    }
  },
  methods: {
    // 点击取消按钮
    // 1新增 跳转 coupon/list
    // 2编辑 由于是新开页  需要关闭当前窗口
    cancelCoupon () {
      if (this.form.id) {
        window.close()
      } else {
        this.$utils.linkTo('/shop/coupon/list')
      }
    },
    refreshDomFn () {
      this.refreshDom = false
      setTimeout(() => {
        this.refreshDom = true
      }, 10)
    },
    // 获取渠道
    async getInfoByCouponType () {
      const res = await this.$store.dispatch('http', {
        api: 'propertyGroupByCouponType',
        query: { id: this.form.typeId }
      })
      if (!res) {
        this.$store.dispatch('showToast', {
          type: 'error',
          message: '未获取到优惠券信息'
        })
      }
      const { propertyGroup } = res
      const channel = await this.$store.dispatch(
        'coupon/getTeamList',
        {},
        { root: true }
      )
      console.log(channel, '获取到的渠道')
      this.channelList = [
        {
          id: '0',
          name: '全部渠道'
        },
        ...channel
      ]
      //   设置优惠券的特性
      if (propertyGroup) {
        propertyGroup.forEach((element) => {
          this.propertyGroupObj[element.groupCode] = element
        })
      }
      this.$forceUpdate()
    },
    disabledDateStart (current) {
      return current && current < moment().subtract(1, 'days')
    },
    computedFormData () {
      const {
        channel,
        goodsLimit,
        orderLimit,
        benefit,
        timeLimit,
        goodsLimitSwitch,
        getLimit,
        getCouponAuto
      } = { ...this.form }
      //   最终form要提交的 特性
      let propertyCodeList = []
      let couponEx = this.form.couponEx
      //   渠道
      if (channel && channel !== '0') {
        propertyCodeList.push('channel')
        couponEx.channel = channel
      }
      //   指定商品
      if (goodsLimitSwitch === '0') {
        console.log('适用于全部商品，不需要传任何参数')
      } else if (goodsLimit) {
        if (goodsLimit === 'goods') {
          propertyCodeList.push('goods')
          couponEx[goodsLimit] = this.couponState.goodsSelectedKeys
        }
        if (goodsLimit === 'goodsType') {
          propertyCodeList.push('goodsType')
          couponEx[goodsLimit] = this.couponState.goodsGroupSelectedKeys
        }
      }

      //   指定门槛
      if (orderLimit && orderLimit !== '0') {
        propertyCodeList.push(orderLimit)
        couponEx[orderLimit] = this.form.orderLimitAmount
      }

      //   优惠内容
      if (benefit) {
        propertyCodeList.push('bonusAmount')
        couponEx.bonusAmount = this.form.benefit
      }

      //   用券时间
      if (timeLimit) {
        propertyCodeList.push(timeLimit)
        if (timeLimit === 'validTime') {
          couponEx[timeLimit] = {
            gmtAvlidStart: this.form.gmtAvlidStart,
            gmtAvlidEnd: this.form.gmtAvlidEnd
          }
        }
        if (timeLimit === 'validDay') {
          couponEx[timeLimit] = this.form.validDay
        }
        if (timeLimit === 'validAfterDay') {
          couponEx[timeLimit] = {
            validDay: this.form.validDay1,
            validAfterDay: this.form.validDay2
          }
        }
      }

      //   限领次数
      // eslint-disable-next-line eqeqeq
      if (getLimit && getLimit != '0') {
        propertyCodeList.push(getLimit)
        couponEx[getLimit] = this.form.getLimitQty
      }

      //   自动领取
      // eslint-disable-next-line eqeqeq
      if (getCouponAuto && getCouponAuto != '0') {
        propertyCodeList.push(getCouponAuto)
      }

      this.form.propertyCodeList = propertyCodeList
      this.form.couponEx = couponEx
      let formCopy = this.$utils.clone(this.form)
      delete formCopy.channel
      delete formCopy.goodsLimitSwitch
      delete formCopy.goodsLimit
      delete formCopy.orderLimit
      delete formCopy.orderLimitAmount
      delete formCopy.benefit
      delete formCopy.timeLimit
      delete formCopy.gmtAvlidEnd
      delete formCopy.gmtAvlidStart
      delete formCopy.validDay
      delete formCopy.validDay1
      delete formCopy.validDay2
      delete formCopy.getLimit
      delete formCopy.getLimitQty
      delete formCopy.getCouponAuto
      console.log(formCopy, 'formCopy')
      return formCopy
    },
    onSubmit () {
      this.validForm()
    },
    async goCreate () {
      let that = this
      let data = this.computedFormData()
      let api = this.form.id ? 'couponUpdate' : 'couponAdd'
      const res = await this.$store.dispatch('http', {
        api: api,
        params: { ...data }
      })
      console.log(res)
      if (res) {
        this.$store.dispatch('showToast', {
          type: 'success',
          message: this.form.id ? '修改成功' : '添加成功'
        })
        setTimeout(() => {
          if (this.form.id) {
            window.close()
          } else {
            that.$utils.linkTo('/shop/coupon/addSuccess')
          }
        }, 1000)
      } else {
        this.$store.dispatch('showToast', {
          type: 'error',
          message: '操作失败'
        })
      }
    },
    async validForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.goCreate()
        } else {
          this.$store.dispatch('showToast', {
            type: 'error',
            message: '请填写完整信息'
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
    width: auto;
    min-width: 1000px;
}
.baseInfo {
    background: #f2f2f2;
    padding: 10px 20px;
    margin-top: 30px;
    font-size: 20px;
}
</style>
