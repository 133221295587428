<template>
    <div>
        <a-form
            ref="form"
            class="al-form"
            layout="inline"
            :model="form"
            :label-col="{ style: 'width: 80px' }"
            :wrapper-col="{ style: 'width: 170px' }"
        >
            <a-form-item label="商品名称:">
                <a-input
                    v-model="form.name"
                    placeholder="请输入"
                />
            </a-form-item>
            <a-form-item label="分组:">
                <a-select
                    style="width:200px;"
                    :key="form.idGoyVirtual"
                    v-model="form.idGoyVirtual"
                    placeholder="请选择"
                >
                    <a-select-option
                        v-for="d in virtualList"
                        :key="d.idGoyVirtual"
                        :value="d.idGoyVirtual"
                    >
                        {{d.nameGoyVirtual}}
                    </a-select-option>
                </a-select>
            </a-form-item>

            <a-row
                class="al-form__btn"
                type="flex"
                justify="end"
            >
                <a-button
                    type="primary"
                    :loading="isQueryLoading"
                    @click="queryList"
                >查询</a-button>
                <a-button
                    @click="resetForm('form')"
                    style="margin-left:20px;"
                >重置</a-button>
            </a-row>
        </a-form>
        <br />
        <!-- 表格 -->
        <a-table
            :columns="columns"
            :data-source="data"
            :rowKey="(record) => record.productId"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            :pagination="pagination"
            @change="currentChange"
            bordered
        >
            <span
                style="color:red;"
                slot="price"
                slot-scope="text,record"
            >
                {{record.price}}.00￥
            </span>
            <span
                slot="productName"
                slot-scope="text,record"
            >
                <img
                    style="width:30px;height:30px;"
                    :src="record.imgUrl"
                />
                {{record.productName}}
            </span>
            <span
                slot="virtualList"
                slot-scope="text,record"
            >
                <a-space>
                    <span
                        v-for="d in record.virtualList"
                        :key="d.goodsId"
                    >
                        {{d.nameGoyVirtual}}
                    </span>
                </a-space>
            </span>

            <span
                slot="activeList"
                slot-scope="text,record"
            >
                <a-space>
                    <span
                        v-for="d in record.activeList"
                        :key="d.couponId"
                    >
                        {{d.couponName}}
                    </span>
                </a-space>
            </span>

        </a-table>
        <br />
        <div style="text-align:center;">
            最多可以添加一千个商品，已经选中了{{selectedRowKeys.length}}条记录
            <a-button @click="$emit('closeModal')">
                取消
            </a-button>
            <a-button
                type="primary"
                @click="onSubmit"
                style="margin-left: 10px;"
            >
                添加
            </a-button>
        </div>
    </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      form: {
        name: '',
        idGoyVirtual: '0'
      },
      isQueryLoading: false,
      columns: [
        {
          title: '商品名称',
          dataIndex: 'productName',
          width: 500,
          ellipsis: true,
          key: 'productName',
          scopedSlots: {
            customRender: 'productName'
          }
        },
        {
          title: '规格',
          dataIndex: 'specification',
          key: 'specification',
          width: 150,
          ellipsis: true
        },
        {
          title: '销售价',
          dataIndex: 'price',
          key: 'price',
          width: 150,
          ellipsis: true,
          scopedSlots: {
            customRender: 'price'
          }
        },
        {
          title: '商品分组',
          dataIndex: 'virtualList',
          key: 'virtualList',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'virtualList'
          }
        },
        {
          title: '库存',
          dataIndex: 'stock',
          key: 'stock',
          width: 100,
          ellipsis: true
        },
        {
          title: '累计净销量',
          dataIndex: 'sales',
          key: 'sales',
          width: 120,
          ellipsis: true
        },
        {
          title: '已参与活动',
          dataIndex: 'activeList',
          key: 'activeList',
          scopedSlots: {
            customRender: 'activeList'
          }
        },
        {
          title: '不可选原因',
          key: 'reasons',
          ellipsis: true
        }
      ],
      rules: {
        name: [{ required: true, message: 'adadsf', trigger: 'blur' }],
        region: [
          {
            required: true,
            message: 'Please select Activity zone',
            trigger: 'change'
          }
        ]
      },
      data: [],
      selectedRowKeys: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) =>
          `共${total}条记录,第${this.pagination.current}页`
      }
    }
  },
  async created () {
    this.reloadList()
    this.selectedRowKeys = this.couponState.goodsSelectedKeys
    // const groupList = await this.$store.dispatch('coupon/getTeamList', {}, { root: true })
  },
  computed: {
    couponState () {
      const {
        state: { coupon }
      } = this.$store
      return coupon
    },
    virtualList () {
      const {
        state: {
          coupon: { virtualList }
        }
      } = this.$store
      return [{ idGoyVirtual: '0', nameGoyVirtual: '全部分类' }, ...virtualList]
    }
  },
  methods: {
    onSubmit () {
      this.$store.commit('coupon/SET_GOODS_SELECTED', this.selectedRowKeys, {
        root: true
      })
      this.$emit('changeGoosLimitCode', 'goods')
      this.$emit('closeModal')
    },
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    // 重置表单
    resetForm () {
      this.form = {}
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => {}) {
      this.pagination.current = 1
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => {}) {
      this.isTableLoading = true
      const params = Object.assign(
        {
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize,
          onsell: 1
        },
        this.form
      )
      const { total, records } = await this.$store.dispatch('http', {
        api: 'goodsForCoupon',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      this.data = records
    },
    // 分页、排序、筛选变化时触发
    currentChange (val) {
      console.log('分页、排序、筛选变化时触发', val)
      this.pagination.pageSize = val.pageSize
      this.pagination.current = val.current
      this.loadList()
    }
  }
}
</script>

<style>
.ant-pagination {
    position: relative;
    text-align: center !important;
}
.ant-pagination-total-text {
    position: absolute !important;
    left: 0 !important;
}

.ant-table-pagination.ant-pagination {
    float: none !important;
}
</style>
