<template>
    <div>
        <!-- 表格 -->
        <a-table
            :columns="columns"
            :data-source="data"
            :rowKey="(record) => record.productId"
            :pagination="pagination"
            @change="currentChange"
            bordered
        >
             <div
                slot="productName"
                slot-scope="text,record"
            >
                <div style="display: flex; align-items: center;">
                    <div style="width:60px;">
                         <img
                            style="width:30px;height:30px;"
                            :src="record.imgUrl"
                        />
                    </div>
                    <div style="flex:1;white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">
                        <div>{{record.productName}}</div>
                        <div>
                            <span style="color:#606266;margin-right:20px;">{{record.specification}}</span>
                            <span style="color:#E00000; margin-right:20px;">￥{{record.price}}.00</span>
                            <span style="color:#8F9299;">库存：{{record.stock}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <span
                slot="action"
                slot-scope="text, record"
            >
                <a-popconfirm
                    title="确定要删除吗？"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="deleteKeys(record.productId)">
                      <a>删除</a>
                </a-popconfirm>
            </span>
        </a-table>
        <br />
    </div>
</template>

<script>
import Vuex from 'vuex'
const { mapState, mapMutations } = Vuex.createNamespacedHelpers('coupon')
export default {
  components: {},
  data () {
    return {
      isQueryLoading: false,
      columns: [
        {
          title: '商品名称',
          dataIndex: 'productName',
          width: 500,
          ellipsis: true,
          key: 'productName',
          scopedSlots: {
            customRender: 'productName'
          }
        },
        {
          title: '操作',
          width: 100,
          key: 'action',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) =>
          `共${total}条记录,第${this.pagination.current}页`
      }
    }
  },
  created () {
    this.loadList()
  },
  computed: {
    ...mapState(['goodsSelectedKeys'])
  },
  methods: {
    ...mapMutations(['DELETE_KEY_IN_GOODS']),
    deleteKeys (key) {
      this.DELETE_KEY_IN_GOODS(key)
      this.data = []
      this.loadList()
    },
    // 查询列表
    queryList () {
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => {}) {
      this.pagination.current = 1
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => {}) {
      if (this.goodsSelectedKeys.length === 0) {
        return
      }
      this.pagination.total = this.goodsSelectedKeys.length
      this.isTableLoading = true

      let startNum = (this.pagination.current - 1) * this.pagination.pageSize
      //   这里很重要。如果从第二页删除起。删没了第2页。那么current需要变。不变则会有问题。
      if (!this.goodsSelectedKeys[startNum]) {
        this.pagination.current--
        startNum = (this.pagination.current - 1) * this.pagination.pageSize
      }
      let endnum = startNum + this.pagination.pageSize
      const params = Object.assign(
        {
          pageSize: this.pagination.pageSize,
          productIds: this.$utils.clone(this.goodsSelectedKeys).splice(startNum, endnum).toString()
        }
      )
      const { records } = await this.$store.dispatch('http', {
        api: 'goodsForCoupon',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      if (records && records.length > 0) {
        this.data = records
      } else {
        this.data = []
      }
    },
    // 分页、排序、筛选变化时触发
    currentChange (val) {
      console.log('分页、排序、筛选变化时触发', val)
      this.pagination.pageSize = val.pageSize
      this.pagination.current = val.current
      this.loadList()
    }
  }
}
</script>

<style>
.ant-pagination {
    position: relative;
    text-align: center !important;
}
.ant-pagination-total-text {
    position: absolute !important;
    left: 0 !important;
}

.ant-table-pagination.ant-pagination {
    float: none !important;
}
</style>
