<template>
    <div>
        <a-form
            ref="form"
            class="al-form"
            layout="inline"
            :model="form"
            :label-col="{ style: 'width: 80px' }"
            :wrapper-col="{ style: 'width: 170px' }"
        >
            <a-form-item label="分组名称:">
                <a-input
                    v-model="form.name"
                    placeholder="请输入"
                />
            </a-form-item>

            <a-row
                class="al-form__btn"
                type="flex"
                justify="end"
            >
                <a-button
                    type="primary"
                    :loading="isQueryLoading"
                    @click="queryList"
                >查询</a-button>
                <a-button
                    @click="resetForm('form')"
                    style="margin-left:20px;"
                >重置</a-button>
            </a-row>
        </a-form>
        <br />
        <!-- 表格 -->
        <a-table
            :columns="columns"
            :data-source="data"
            :rowKey="(record) => record.idGoyVirtual"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            :pagination="pagination"
            @change="currentChange"
            bordered
        >
        </a-table>
        <br />
        <div style="text-align:center;">
            最多可以添加一千个商品，已经选中了{{selectedRowKeys.length}}条记录
            <a-button @click="$emit('closeModal')">
                取消
            </a-button>
            <a-button
                type="primary"
                @click="onSubmit"
                style="margin-left: 10px;"
            >
                添加
            </a-button>
        </div>
    </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      form: {
        name: '',
        idGoyVirtual: ''
      },
      isQueryLoading: false,
      columns: [
        {
          title: '分组',
          dataIndex: 'nameGoyVirtual',
          width: 500,
          ellipsis: true,
          key: 'nameGoyVirtual'
        }
      ],
      rules: {
        name: [{ required: true, message: 'adadsf', trigger: 'blur' }]
      },
      data: [],
      selectedRowKeys: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) =>
          `共${total}条记录,第${this.pagination.current}页`
      }
    }
  },
  created () {
    this.reloadList()
    this.selectedRowKeys = this.couponState.goodsGroupSelectedKeys
  },
  computed: {
    couponState () {
      const { state: { coupon } } = this.$store
      return coupon
    }
  },
  methods: {
    onSubmit () {
      this.$store.commit('coupon/SET_GOODS_GROUP_SELECTED', this.selectedRowKeys, { root: true })
      this.$emit('changeGoosLimitCode', 'goodsType')
      this.$emit('closeModal')
    },
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    // 重置表单
    resetForm () {
      this.form = {}
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => {}) {
      this.pagination.current = 1
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => {}) {
      this.isTableLoading = true
      const params = Object.assign(
        {
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize
        },
        this.form
      )
      const { total, records } = await this.$store.dispatch('http', {
        api: 'goodsGroupForCoupon',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      this.data = records
    },
    // 分页、排序、筛选变化时触发
    currentChange (val) {
      console.log('分页、排序、筛选变化时触发', val)
      this.pagination.pageSize = val.pageSize
      this.pagination.current = val.current
      this.loadList()
    }
  }
}
</script>

<style>
.ant-pagination {
    position: relative;
    text-align: center !important;
}
.ant-pagination-total-text {
    position: absolute !important;
    left: 0 !important;
}

.ant-table-pagination.ant-pagination {
    float: none !important;
}
</style>
